var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('CRow',[_c('loading-overlay',{attrs:{"active":_vm.apiStateFormLoading,"is-full-page":true,"loader":"bars"}}),_c('loading-overlay',{attrs:{"active":_vm.loading,"is-full-page":true,"loader":"bars"}}),_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: _vm.$t('label.brand'),
          placement: 'top-end',
        }),expression:"{\n          content: $t('label.brand'),\n          placement: 'top-end',\n        }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","color":"add"},on:{"click":function($event){return _vm.activarModal()}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$t('label.nueva'))+" ")])],1)],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","sorter":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":5,"pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"acciones",fn:function({item, index}){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.edit')+' '+_vm.$t('label.brand'),
                  placement: 'top-end'
                  }),expression:"{\n                  content: $t('label.edit')+' '+$t('label.brand'),\n                  placement: 'top-end'\n                  }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.editModal(item, index)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])}),_c('modal-brand',{on:{"set-modal-brand-list":_vm.setBrandList}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }